import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import sendFormReducer from '../features/SendForm/sendFormSlice';
import retrieveFormReducer from '../features/RetrieveForm/retrieveFormSlice';
import configReducer from '../features/Config/configSlice';
import { TypedUseSelectorHook, useSelector as useAnySelector } from 'react-redux';
import { AppConfig } from '../config/appconfig';
import validationSlice from '../features/Validation/validationSlice';

export function getStore(config: AppConfig) {

  return configureStore({
    preloadedState: { config: config },
    reducer: {
      sendForm: sendFormReducer,
      retrieveForm: retrieveFormReducer,
      config: configReducer,
      validation: validationSlice
    }
  });
}

export const useSelector: TypedUseSelectorHook<RootState> = useAnySelector;

type storeType = ReturnType<typeof getStore>;

export type RootState = ReturnType<storeType['getState']>;
export type AppThunk = ThunkAction<Promise<void>, RootState, unknown, Action<string>>;
