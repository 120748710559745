import { useContext } from 'react';
import { httpclient as client } from 'typescript-http-client';
import { UserContext } from '@u4/next-authentication';

export function useAccessToken(): string {
  const user: { access_token: string} = useContext(UserContext);
  
  const token = user.access_token;

  return token;
}

export class AuthorizationHttpFilter implements client.Filter<any, any> {

  token: string;

  constructor(token: string) {
    this.token = token;
  }

  doFilter(request: client.Request, chain: client.FilterChain<any>): Promise<client.Response<any>> {
    request.addHeader('Authorization', `Bearer ${this.token}`);

    return chain.doFilter(request);
  }
}