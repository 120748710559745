import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Components, { textFieldValidationStates } from '@u4/next-components';
import '@u4/next-components/lib/style.css';
import {
   setUserId,setPassword,
   setWebsite, 
   setDocumentTypeOptions,
   setEmployersOptions,
   setDocumentType,
   checkEmployers,setTranslations,
   sendAsync, isValid,
  setError, setSuccess, finishSending
} from './retrieveFormSlice';
import EmployerDto from '../../app/dto/employerDto';
import DocumentTypeDto from '../../app/dto/documentTypeDto';
import {sendTranslationRequest, sendApiRequest} from '../../app/sender';
import { useAccessToken } from '../../app/authorization';
import _ from 'lodash';
import './retrieveFormStyles.css';
import {RootState} from '../../app/store';
import { useApi } from '../../app/apiClient';
import OperationDto from '../../app/dto/operationDto';
import Modal from '../Modal/Modal';
import { useConfig } from '../../config/appconfig';

export const RetrieveForm: React.SFC = () => {
  const { userId, password, website, documentType, documentTypesOptions, websiteOptions,
    employersOptions, checkedEmployers, translations, isSending, isError, isSuccess } = useSelector(
      (state: RootState) => {
        return {
          userId: state.retrieveForm.userId,
          password: state.retrieveForm.userPassword,
          website: state.retrieveForm.website,
          documentType: state.retrieveForm.documentType,
          documentTypesOptions: state.retrieveForm.documentTypeOptions,
          websiteOptions: state.retrieveForm.websiteOptions,
          employersOptions: state.retrieveForm.employersOptions,
          checkedEmployers: _.at(state.retrieveForm.employersOptions, state.retrieveForm.employers),
          translations: state.retrieveForm.translations,
          isSending: state.retrieveForm.isSending,
          isError: state.retrieveForm.isError,
          isSuccess: state.retrieveForm.isSuccess,
        };
      }
    );

  const config = useConfig();
  const isDataValid = useSelector(isValid);
  const token = useAccessToken();
  const [validate, setValidate] = useState(false);
  const { operationId } = useParams();
  const dispatch = useDispatch();
  const apiClient = useApi();
  let interval: number;

  const employersTableColumns = [
    { title: translations.Employers, fieldTemplate: '{name}', editable: false, width: 'MEDIUM', sortable: false }];

  function fetchTranslations(): void {
    sendTranslationRequest('/bundles/hmrcRetrieve', config, token)
      .then(res => dispatch(setTranslations(res)));
  }

  function fetchEmployers(): void {
    sendApiRequest<Array<EmployerDto>>('employers?operationId=' + operationId, 'GET', config, token)
      .then(res => dispatch(setEmployersOptions(res)));
  }

  function fetchDocumentTypes(): void {
    sendApiRequest<Array<DocumentTypeDto>>('documentTypes?operationId=' + operationId, 'GET', config, token)
      .then(res => dispatch(setDocumentTypeOptions(res)));
  }

  function fetchOperationStatus(): void {
    sendApiRequest<OperationDto>(`operations/${operationId}`, 'GET', config, token)
      .then(res => checkOperationStatus(res.status));
  }

  function checkOperationStatus(status: string): void {
    switch(status){
      case 'Done': {
        window.clearInterval(interval);
        dispatch(setSuccess(true));
        dispatch(finishSending());
        break;
      }
      case 'Error': {
        window.clearInterval(interval);
        dispatch(setError(true));
        dispatch(finishSending());
        break;
      }
    }
  }

  function startInterval(): void{
    interval = window.setInterval(() => {
      fetchOperationStatus();
    }, 5000);    
  }

  useEffect(() => {
    if (token) {
      fetchTranslations();
      fetchEmployers();
      fetchDocumentTypes();
    }
  }, [token]);

  function shouldShowEmployersValidation(){
    return _.size(checkedEmployers) == 0 && validate;
  }

  async function handleSend(e: React.MouseEvent): Promise<void> {
    setValidate(true);

    if (operationId) {
      await dispatch(sendAsync(
        apiClient,
        {
          OperationId: operationId,
          User: userId,
          Password: password,
          WebSite: website,
          Employers: checkedEmployers.map(t=>t.name),
          DocumentType: documentType,
          Timeout: config.requestTimeout
        }));

        if(!isError){
          startInterval();
        }
   }
  }

  function getTableValidationCssClass(){
    let cssClass = 'validated-element';
    if(_.size(employersOptions) > 0)
      cssClass += '-margin';
    if(!shouldShowEmployersValidation())
      cssClass += '--hidden';  

    return cssClass;
  }

  return (  
    <Components.Container isColumn>
      <Components.Panel
        header={translations.RetrieveDocumentsTitle}
        maxWidth='50vw'
        flex='auto'
        isColumn
        footer={
          <Components.Container isRow>
          <Components.Button
            name='retrieve'
            text={translations.RetrieveButton}
            size="small"
            type="HAPPY"
            onClick={handleSend}
            disabled={isSending || !isDataValid}
          />
          <Components.DisplayField
          value={translations.OperationInProgress} 
          dataType="text"
          cssClass={isSending ? '' : 'hidden'}
          />
          </Components.Container>
        }>
        <Components.Container key='retrieveForm' isRow>
          <Components.Container isColumn>
            <Components.InputField
              name='userId'
              label={translations.UserId}
              dataKey='userId'
              dataType='String'
              size='large'
              value={userId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setUserId(e.target.value))}
              mandatory={true}
              validationStatus={(validate && !userId) ? textFieldValidationStates.ERROR : ''}
              description={(validate && !userId) ? translations.NotEmptyValidation : ''}
            />
            <Components.InputField
              name='password'
              label={translations.Password}
              dataKey='password'
              dataType='password'
              size='large'
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(setPassword(e.target.value))}
              mandatory={true}
              validationStatus={(validate && !password) ? textFieldValidationStates.ERROR : ''}
              description={(validate && !password) ? translations.NotEmptyValidation : ''}
            />
            <Components.Select
              name='website'
              fieldName='website'
              label={translations.Website}
              options={websiteOptions}
              displayField='name'
              valueField='name'
              size='large'
              value={website}
              onChange={(e: React.ChangeEvent<HTMLOptionElement>) => dispatch(setWebsite(eval(e.target.value)?.name || ''))}
              mandatory={true}
            />
          </Components.Container>
          <Components.Container isColumn>
            <Components.Select
              name='documentType'
              fieldName='documentType'
              label={translations.DocumentType}
              options={documentTypesOptions}
              filtering={true}
              displayField='typeName'
              valueField='typeName'
              size='large'
              value={documentType}
              onChange={(e: React.ChangeEvent<HTMLOptionElement>) => dispatch(setDocumentType(eval(e.target.value)?.typeName || ''))}
              mandatory={true}

              validationType={(validate && !documentType) ? textFieldValidationStates.ERROR : ''}
              validationStatus={(validate && !documentType) ? textFieldValidationStates.ERROR : ''}
              description={(validate && !documentType) ? translations.NotEmptyValidation : ''}
            />
          
            <Components.Table
              height='200px'
              id='employersList'
              onSelectionChanged={(itemIndexes: Array<string>) => dispatch(checkEmployers(itemIndexes))}
              columns={employersTableColumns}
              cssClass={shouldShowEmployersValidation() ? 'validated-table' : ''}
              data={[...employersOptions]}
              config={{
                allowAddRow: false,
                allowRemoveRow: false,
                multiselect: true,
                readOnly: true,
                resizableColumns: false,
                pagination: 20,
              }}
            /> 
            <Components.Container isRow 
              cssClass={getTableValidationCssClass()}
              alignItems='center'
              alignContent='flex-start'>
              <Components.Icon icon='exclamation-circle'/>
              <Components.FieldLabel
                fieldType='text'
                text={translations.NotEmptyArrayValdiation}>
              </Components.FieldLabel>
            </Components.Container>
          </Components.Container>
        </Components.Container>
      </Components.Panel>
      <Modal 
        onClose={()=> dispatch(setSuccess(false))} 
        isVisible={isSuccess} 
        type="success"
        notClosable={false}
        body={translations.OperationSuccessfull} 
        title={translations.ModalSuccessTitle}/>
      <Modal 
        onClose={()=> dispatch(setError(false))} 
        isVisible={isError} 
        type="warning" 
        notClosable={false}
        body={translations.OperationError} 
        title={translations.ModalErrorTitle}/>  
      </Components.Container>
  );
};
