import TranslationDataDto from './dto/translationDataDto';
import { AppConfig } from '../config/appconfig';
import { ValidationError } from './errors/ValidationError';

function sendRequest<T>(url: string, httpMethod: string, accessToken: string): Promise<T> {
  return fetch(url,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      credentials: 'same-origin',
      method: httpMethod
    })
    .then(response => {
      return response.json().then(body => {
        if (!response.ok) {
          if (!body || !body.status) throw new Error(response.statusText);

          switch(body.status) {
            case 400:
              throw new ValidationError(body.detail);
            default:
              throw new Error(response.statusText);
          }
        }
        return body as Promise<T>;
      });
    });
}

export function sendApiRequest<T>(url: string, httpMethod: string, appConfig: AppConfig, accessToken: string): Promise<T> {
  return sendRequest<T>(appConfig.apiUri + url, httpMethod, accessToken);
}

export function sendTranslationRequest(url: string, appConfig: AppConfig, accessToken: string): Promise<Array<TranslationDataDto>> {
  const lang = window.navigator.languages
  ? window.navigator.languages[0]
  : (window.navigator.language || 'en-GB');

  return sendRequest<Array<TranslationDataDto>>(appConfig.translationApiUri + 'titles/' + lang + url + '?allowAutomatedTranslations=false',
    'GET', accessToken)
    .catch(error => Promise.resolve(error));
}