import React, { useEffect, useState } from 'react';
import './App.css';
import Components from '@u4/next-components';
import '@u4/next-components/lib/style.css';
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom';
import Authentication from '@u4/next-authentication';
import getOidcSettings from './config/oidcsettings';
import { Operation } from './features/OperationContainer/operation';
import { useConfig } from './config/appconfig';

function App() {
  const [redirect, setRedirect] = useState('');
  const appConfig = useConfig();

  function getIDSRedirect(): string {
    const pathArray = window.location.pathname.split('/');
    let newPathname = '';
    for (let i = 1; i < pathArray.length; i++)
      newPathname += pathArray[i] + '/';

    newPathname = newPathname.substring(0, newPathname.length - 1);
    return newPathname;
  }

  function getRedirect(): void {
    const query = new URLSearchParams(window.location.search);
    const redirectUrl = query.get('redirect');
    setRedirect(redirectUrl || '');
  }

  useEffect(() => {
    getRedirect();
  }, []);

  function onUserLoaded(user: any) {
    if (redirect !== '') {
      window.location.replace(appConfig.appUri + redirect);
    }
  }

  return (
    <div className='App'>
      <Authentication
        oidcSettings={getOidcSettings(getIDSRedirect(), appConfig)}
        renderNotAuthenticated={() => 'Loading...'}
        automaticSignin={true}
        userLoaded={onUserLoaded}>
        <Components.MainContainer
          key='mainApp'
          footer={       
            <Components.Container isRow justifyContent={"end"}>
              { appConfig.appVersion }
            </Components.Container>
          }>
            <Router>
              <Switch>
                <Route path='/:operationId'>
                  <Operation/>
                </Route>
              </Switch>
            </Router>
        </Components.MainContainer>
      </Authentication>
    </div>
  );
}

export default App;
