import TranslationDataDto from '../dto/translationDataDto';
import RetrieveTranslations from '../dto/translations/retrieveTranslations';
import _ from 'lodash';

export default function mapToRetrieveTranslations(translationDto: Array<TranslationDataDto>): RetrieveTranslations {
    const retrieveTranslations: RetrieveTranslations = {
        RetrieveDocumentsTitle: getTranslation(340003) || "Retrieve documents for HMRC",
        RetrieveButton: getTranslation(339995) || "Retrieve",
        UserId: getTranslation(60248) || "UserId",
        Password: getTranslation(40951) || "Password",
        Website: getTranslation(892358) || "Website",
        DocumentType: getTranslation(40379) || "Document type",
        Employers: getTranslation(340004) || "Employer(s)",
        NotEmptyValidation: getTranslation(340150) || "Field must have a value",
        NotEmptyArrayValdiation: "You must select a value",
        ModalErrorTitle: "Error",
        ModalSuccessTitle: "Success",
        OperationError: "Operation was completed with errors.",
        OperationSuccessfull: "Operation was completed successfully. Please close the window and refresh Unit4 ERP screen.",
        OperationInProgress: "Retrieving..."
    }

    function getTranslation(id: Number): string | undefined {
       return  _.find(translationDto, function(t: TranslationDataDto) { return t.id === id; } )?.text;
    }

    return retrieveTranslations;
}
