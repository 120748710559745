import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import WebsiteOptions from '../../app/dto/websiteOption';
import RetrieveFormDto from '../../app/dto/retrieveDataDto';
import EmployerDto from '../../app/dto/employerDto';
import DocumentTypeDto from '../../app/dto/documentTypeDto';
import _ from 'lodash';
import RetrieveTranslations from '../../app/dto/translations/retrieveTranslations';
import TranslationDataDto from '../../app/dto/translationDataDto';
import mapToRetrieveTranslations from '../../app/mappers/retrieveTranslationMapper';
import RetrieveOperationParametersDto from '../../app/dto/operations/RetrieveOperationParametersDto';
import { httpclient as Http } from 'typescript-http-client';
import ExecuteOperationDto from '../../app/dto/operations/ExecuteOperationDto';

interface RetrieveFormState extends RetrieveFormDto {
  websiteOptions: Array<WebsiteOptions>;
  employersOptions: Array<EmployerDto>;
  documentTypeOptions: Array<DocumentTypeDto>;
  translations: RetrieveTranslations;
  isSending: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const websiteOptions: Array<WebsiteOptions> = [{ 'name': 'Test site' }, { 'name': 'Live site' }];
const documentTypeOptions: Array<DocumentTypeDto> = [];
const employersOptions: Array<EmployerDto> = [];

const initialState: RetrieveFormState = {
  userId: '',
  userPassword: '',
  website: websiteOptions[0].name,
  documentType: '',
  employers: [],
  websiteOptions: websiteOptions,
  employersOptions: employersOptions,
  documentTypeOptions: documentTypeOptions,
  translations: mapToRetrieveTranslations([]),
  isSending: false,
  isError: false,
  isSuccess: false
};

export const slice = createSlice({
  name: 'retrieve',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.userPassword = action.payload;
    },
    setWebsite: (state, action: PayloadAction<string>) => {
      state.website = action.payload;
    },
    setDocumentType: (state, action: PayloadAction<string>) => {
      state.documentType = action.payload;
    },
    setDocumentTypeOptions: (state, action: PayloadAction<Array<DocumentTypeDto>>) => {
      state.documentTypeOptions = action.payload;
    },
    setEmployersOptions: (state, action: PayloadAction<Array<EmployerDto>>) => {
      state.employersOptions = action.payload;
    },
    checkEmployers: (state, action: PayloadAction<Array<string>>) => {
      state.employers = action.payload;
    },
    setTranslations: (state, action: PayloadAction<Array<TranslationDataDto>>) => {
      state.translations = mapToRetrieveTranslations(action.payload);
    },
    beginSending: (state) => {
      state.isSending = true;
    },
    finishSending: (state) => {
      state.isSending = false;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    }
  },
});

export interface RetrieveParameters {
  OperationId: string;
  User: string;
  Password: string;
  WebSite: string;
  DocumentType: string;
  Employers: string[];
  Timeout: number;
}

export function sendAsync(apiClient: Http.HttpClient, parameters: RetrieveParameters): AppThunk {
  return async (dispatch) => {
    
    await dispatch(beginSending());

    try {

      const setOperationParameters = new Http.Request(`operations/${parameters.OperationId}/parameters`,
        {
          method: 'PUT',
          timeout: parameters.Timeout,
          body: {
            IsTestSite: (parameters.WebSite === 'Test site').toString(),
            DocumentType: parameters.DocumentType,
            Employers: parameters.Employers.toString()
          } as RetrieveOperationParametersDto
        });

      await apiClient?.execute<void>(setOperationParameters);

      const executeOperation = new Http.Request(`operations/${parameters.OperationId}/executions`,
        {
          method: 'POST',
          timeout: parameters.Timeout,
          body: {
            User: parameters.User,
            Password: parameters.Password,
          } as ExecuteOperationDto
        });

      await apiClient?.execute<void>(executeOperation);

    } 
    catch(error){
      dispatch(setError(true));
      dispatch(finishSending());
    }
  };
}

export const { setUserId, setPassword, setWebsite, setDocumentType, setDocumentTypeOptions,
  setEmployersOptions, checkEmployers, setTranslations, beginSending, finishSending, setError, setSuccess } = slice.actions;

export const isValid = (state: RootState) => state.retrieveForm.userId && state.retrieveForm.userPassword
  &&_.size(state.retrieveForm.employers) > 0 && state.retrieveForm.website && state.retrieveForm.documentType;

export default slice.reducer;
